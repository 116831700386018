import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register, setError, modal } from 'actions/main.actions';
import {
  filteredErrors,
  selectedPricingPlan,
  variablesSelector,
} from 'selectors/main.selectors';
import Base from './Base';
import { withRouter } from 'react-router';
import Recaptcha from 'react-recaptcha';

class Register extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      agree: false,
      verified: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaInstance = null;
  }

  verifyCallback() {
    this.setState({ verified: true });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    this.props.setError('register', null, 'fields');
  }

  onSubmit(event) {
    event.preventDefault();
    if (!this.state.verified) return this.setState({ verified: false });

    if (!this.state.agree) {
      this.props.setError(
        'register',
        { agree: 'You must agree before submitting.' },
        'fields'
      );
      return false;
    }

    const { email, password, password_confirmation } = this.state;
    this.props.register(email, password, password_confirmation).then(() => {
      if (
        this.props.selectedPricingPlan &&
        'professional' === this.props.selectedPricingPlan.system_name
      ) {
        this.props.showModal('payment');
      } else {
        this.props.history.push('/search');
      }
    });
  }

  fieldError(field) {
    let errors = this.props.errors;
    if (!errors) return false;
    if ('undefined' === typeof errors[field]) return false;

    return Array.isArray(errors[field]) ? errors[field][0] : errors[field];
  }

  render() {
    let errors = {};
    ['username', 'password', 'password_confirmation', 'agree'].forEach(
      (item) => (errors[item] = this.fieldError(item))
    );
    const variables = this.props.variables;
    return (
      <Base {...this.props} modalId="register">
        <div className="text-center mb-4">
          <h4 className="h3 mb-1">Create Account</h4>
          <span>No credit card required</span>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              className={
                'form-control' + (errors['username'] ? ' is-invalid' : '')
              }
              value={this.state.email}
              onChange={this.handleChange}
            />

            <div className="invalid-feedback">{errors['username']}</div>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              className={
                'form-control' + (errors['password'] ? ' is-invalid' : '')
              }
              value={this.state.password}
              onChange={this.handleChange}
            />
            <small className="text-muted">Must be at least 8 characters</small>
            <div className="invalid-feedback">{errors['password']}</div>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password_confirmation"
              placeholder="Confirm password"
              className="form-control"
              value={this.state.password_confirmation}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            {/* <Recaptcha
              ref={(e) => (this.recaptchaInstance = e)}
              sitekey={process.env.RECAPTCHA_KEY}
              verifyCallback={this.verifyCallback}
            /> */}
            {/* {false === this.state.verified ? (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {variables.recaptcha_failed_message || 'ReCaptcha Failed'}
              </div>
            ) : null} */}
          </div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className={
                'custom-control-input' + (errors['agree'] ? ' is-invalid' : '')
              }
              id="agree"
              name="agree"
              value={this.state.agree}
              onChange={this.handleChange}
            />
            <label
              className="custom-control-label text-small text-muted"
              htmlFor="agree"
            >
              I agree to{' '}
              <a href="/terms-conditions" target="_blank">
                Terms &amp; Conditions
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </label>
            <div className="invalid-feedback">{errors['agree']}</div>
          </div>
          <div className="form-group mt-3">
            <button className="btn-block btn btn-primary-2" type="submit">
              Sign up
            </button>
          </div>
          <hr />
          <div className="text-center text-small text-muted">
            <span>
              Already have an account?{' '}
              <a href="#" onClick={() => this.props.showModal('login')}>
                Log In
              </a>
            </span>
          </div>
        </form>
      </Base>
    );
  }
}

const MapStateToProps = (state) => ({
  errors: filteredErrors(state)('register', 'fields'),
  selectedPricingPlan: selectedPricingPlan(state),
  variables: variablesSelector(state),
});

const MapDispatchToProps = (dispatch) => ({
  register: (email, password, password_confirmation) =>
    dispatch(register(email, password, password_confirmation)),
  setError: (group, errors, type) => dispatch(setError(group, errors, type)),
});

export default withRouter(
  connect(MapStateToProps, MapDispatchToProps)(Register)
);
