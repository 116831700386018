import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {checkAuth} from 'actions/main.actions';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        return checkAuth() === true
            ? <Component {...props} {...rest} />
            : <Redirect to='/'/>
    }} />
);

export default PrivateRoute;