import React from 'react';
import numeral from 'numeral';

const SalariesTable = ({results = {}, preloader = false}) => {
    const mainTableFields = [{
        field: 'salary',
        title: 'Salary'
    }, {
        field: 'bonus',
        title: 'Bonus'
    }, {
        field: 'stock_awards',
        title: 'Stock Awards'
    }, {
        field: 'option_awards',
        title: 'Option Awards'
    }, {
        field: 'non_equity_compensation',
        title: 'Non-Equity Incentive Plan Compensation'
    },
    //     {
    //     field: 'sar_awards',
    //     title: 'SAR Awards'
    // },
        {
        field: 'change_in_pension_compensation',
        title: 'Change in Pension Value and Nonqualified Deferred Compensation Earnings'
    }, {
        field: 'all_other_compensation',
        title: 'All Other Compensation'
    }, {
        field: 'total',
        title: 'Total'
    }];


    return (
        <>
        <div className="row">
            <div className="col-8">
                <h4>Summary Compensation Table</h4>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-bordered table-salaries">
                    <thead>
                    <tr>
                        <td/>
                        <td>Count</td>
                        <td>25th</td>
                        <td>Median</td>
                        <td>75th</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        mainTableFields.map((item, key) =>
                            (<tr key={key}>
                                <td>{item.title}</td>
                                <td>{numeral(results[item.field].count).format('0,0')}</td>
                                <td>{numeral(results[item.field].quartile_25).format('0,0')}</td>
                                <td>{numeral(results[item.field].avg).format('0,0')}</td>
                                <td>{numeral(results[item.field].quartile_75).format('0,0')}</td>
                            </tr>)
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
        </>
    );
};

export default SalariesTable;