import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userSelector, settingsSelector} from 'selectors/main.selectors';
import {getPage, preloader} from 'actions/main.actions';
import {ReactTitle, MetaTags} from 'react-meta-tags';
import {HIDE_NAV} from "../reducers/main.reducers";

class Home extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            page: null
        };
    }

    componentDidMount() {
        let self = this;
        this.props.preloader();
        getPage('home').then((result) => {
            self.setState({page: result}, () =>{
                self.props.preloader(false);
                self.props.hideNav();
            });
        }).catch(error => self.props.history.push('/404'));
    }

    render() {
        let page = this.state.page;

        return (
            page ? (
                <>
                <ReactTitle title={`${page.title}`}/>
                <MetaTags>
                    {
                        ('undefined' !== typeof this.props.settings.variables) ?
                            <meta name="description" content={`${this.props.settings.variables.app_description || ''}`} />
                        :
                        null
                    }
                </MetaTags>
                <div dangerouslySetInnerHTML={{__html: page.body}}></div>
                </>
            ) : null
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    settings: settingsSelector(state),
});

const MapDispatchToProps = dispatch => ({
    preloader: (state) => dispatch(preloader(state)),
    hideNav: () => dispatch({type: HIDE_NAV})
});

export default connect(MapStateToProps, MapDispatchToProps)(Home);
