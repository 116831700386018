import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, preloader, setError } from 'actions/main.actions';
import {
  filteredErrors,
  userSelector,
  variablesSelector,
} from 'selectors/main.selectors';
import Recaptcha from 'react-recaptcha';

class ContactForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      contactName: '',
      contactEmail: '',
      contactCompany: '',
      contactPhone: '',
      contactMessage: '',
      isSent: false,
      verified: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaInstance = null;
  }

  handleChange(event) {
    this.props.setError('contact-form', null, 'fields');
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSubmit(event) {
    event.preventDefault();
    if (!this.state.verified) return this.setState({ verified: false });

    this.props.preloader();
    message(this.state)
      .then((result) => {
        this.props.preloader(false);
        this.setState({ isSent: true });
      })
      .catch((error) => {
        this.props.setError('contact-form', error, 'fields');
        this.props.preloader(false);
      });
  }

  fieldError(field) {
    let errors = this.props.errors;
    if (!errors) return false;
    if ('undefined' === typeof errors[field]) return false;

    return Array.isArray(errors[field]) ? errors[field][0] : errors[field];
  }

  verifyCallback() {
    this.setState({ verified: true });
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.user) return state;

    return {
      ...state,
      contactEmail: props.user.email,
      contactName: props.user.name,
    };
  }

  render() {
    let errors = {};
    [
      'contact-name',
      'contact-email',
      'contact-company',
      'contact-phone',
      'contact-message',
    ].forEach((item) => (errors[item] = this.fieldError(item)));
    let user = this.props.user;
    const variables = this.props.variables;

    return (
      <section
        className="contact-form bg-primary-alt pt-5"
        id="contact_us_section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8 col-xl-6">
              <div className="text-center mb-4">
                <h2 className="h1">
                  {variables.contact_form_title || 'Leave a message'}
                </h2>
                <p className="lead">
                  {variables.contact_form_subtitle ||
                    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'}
                </p>
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  {!this.state.isSent ? (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {variables.contact_form_name_label || 'Your Name *'}
                          </label>
                          <input
                            name="contactName"
                            type="text"
                            value={this.state.contactName}
                            onChange={this.handleChange}
                            className={
                              'form-control' +
                              (errors['contact-name'] ? ' is-invalid' : '')
                            }
                            required
                            readOnly={!!user}
                          />
                          <div className="invalid-feedback">
                            {errors['contact-name']}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {variables.contact_form_email_label ||
                              'Email Address *'}
                          </label>
                          <input
                            name="contactEmail"
                            type="email"
                            value={this.state.contactEmail}
                            onChange={this.handleChange}
                            className={
                              'form-control' +
                              (errors['contact-email'] ? ' is-invalid' : '')
                            }
                            required
                            readOnly={!!user}
                          />
                          <div className="invalid-feedback">
                            {errors['contact-email']}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {variables.contact_form_company_label ||
                              'Company Name'}
                          </label>
                          <input
                            name="contactCompany"
                            type="text"
                            value={this.state.contactCompany}
                            onChange={this.handleChange}
                            className={
                              'form-control' +
                              (errors['contact-company'] ? ' is-invalid' : '')
                            }
                          />
                          <div className="invalid-feedback">
                            {errors['contact-company']}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {variables.contact_form_number_label ||
                              'Contact Number'}
                          </label>
                          <input
                            name="contactPhone"
                            type="tel"
                            value={this.state.contactPhone}
                            onChange={this.handleChange}
                            className={
                              'form-control' +
                              (errors['contact-phone'] ? ' is-invalid' : '')
                            }
                          />
                          <div className="invalid-feedback">
                            {errors['contact-phone']}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>
                            {variables.contact_form_message_label || 'Message'}
                          </label>
                          <textarea
                            className={
                              'form-control' +
                              (errors['contact-message'] ? ' is-invalid' : '')
                            }
                            onChange={this.handleChange}
                            name="contactMessage"
                            rows={10}
                            placeholder={
                              variables.contact_form_message_placeholder ||
                              'How can we help?'
                            }
                            value={this.state.contactMessage}
                            required
                          />
                          <div className="invalid-feedback">
                            {errors['contact-message']}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        {/* <Recaptcha
                          ref={(e) => (this.recaptchaInstance = e)}
                          sitekey={process.env.RECAPTCHA_KEY}
                          verifyCallback={this.verifyCallback}
                          elementID="about-recaptcha"
                        /> */}
                        {/* {false === this.state.verified ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: 'block' }}
                          >
                            {variables.recaptcha_failed_message ||
                              'ReCaptcha Failed'}
                          </div>
                        ) : null} */}
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className={
                            'custom-control-input' +
                            (errors['agree'] ? ' is-invalid' : '')
                          }
                          id="agree-about"
                          name="agree"
                          value={this.state.agree}
                          onChange={this.handleChange}
                        />
                        <label
                          className="custom-control-label text-small text-muted"
                          htmlFor="agree-about"
                        >
                          I agree to Complevel's{' '}
                          <a href="/privacy-policy" target="_blank">
                            Privacy Policy
                          </a>
                        </label>
                        <div className="invalid-feedback">
                          {errors['agree']}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12">
                    {this.state.isSent ? (
                      <div className="alert alert-success" role="alert">
                        {variables.contact_form_success_message ||
                          'Thanks, a member of our team will be in touch shortly.'}
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-primary-2">
                        <span>
                          {variables.contact_form_send_btn || 'Send Enquiry'}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const MapStateToProps = (state) => ({
  errors: filteredErrors(state)('contact-form', 'block'),
  user: userSelector(state),
  variables: variablesSelector(state),
});

const MapDispatchToProps = (dispatch) => ({
  setError: (group, errors, type) => dispatch(setError(group, errors, type)),
  preloader: (status) => dispatch(preloader(status)),
});

export default connect(MapStateToProps, MapDispatchToProps)(ContactForm);
