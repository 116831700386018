import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import {checkAuth, getUser, modal, getSettings, preloader} from 'actions/main.actions';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {Home, Search, Static, Results, Pricing, NotFound} from 'pages';
import {StripeProvider} from 'react-stripe-elements';
import {Forgot, Login, Register, Payment, ChangePassword} from './components/Modals';
import PrivateRoute from './components/PrivateRoute';
import {globalPreloader, modals, settingsSelector} from './selectors/main.selectors';
import numeral from 'numeral';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import {ToastContainer} from "react-toastify";


const history = createBrowserHistory();
history.listen((location, action) => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
});

numeral.register('locale', 'main', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'k',
        million: 'M',
        billion: 'B',
        trillion: 'T'
    },
});
numeral.locale('main');


class App extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            stripe_public_key: '',
            reset_password_token: null,
            reset_password_email: null
        };
        if(('undefined' !== typeof process.env.GA_KEY) && ('' !== process.env.GA_KEY)){
            ReactGA.initialize(process.env.GA_KEY);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    componentDidMount() {
        let {showPreloader} = this.props;
        showPreloader();
        let init = [];
        init.push(this.props.getSettings());
        if(checkAuth())
            init.push(this.props.getUser());

        let self = this;
        Promise.all(init).then(values => {
            let settings = values[0];
            self.setState({stripe_public_key: settings.stripe_public_key});
            showPreloader(false);
        }).catch(() => {
            showPreloader(false);
        });

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let needResetPassword = params.get('reset_password_token');
        let resetPasswordEmail = params.get('email');
        if(needResetPassword){
            this.setState({reset_password_token: needResetPassword, reset_password_email: resetPasswordEmail});
            this.props.modal('change_password', true);
        }
    }

    render() {
        return (
            <Router history={history}>
                <div className={this.props.preloader ? 'preloader' : ''}>
                    <div className={'content'}>
                        <ToastContainer />
                        <Header showModal={this.props.modal}/>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <PrivateRoute exact path="/search" component={Search}/>
                            <PrivateRoute path="/search/customize" component={Search} searchType="customize"/>
                            <PrivateRoute path="/results" component={Results} />
                            <Route path="/404" component={NotFound} />
                            <Route path="/:page" component={Static} />
                        </Switch>

                        {
                            !this.props.user ? (
                                <>
                                    <Login
                                        isShow={this.props.modals.login}
                                        showModal={this.props.modal}/>

                                    <Register
                                        isShow={this.props.modals.register}
                                        showModal={this.props.modal}/>

                                    <Forgot
                                        isShow={this.props.modals.forgot}
                                        showModal={this.props.modal}/>
                                    <ChangePassword
                                        history={history}
                                        isShow={this.props.modals.change_password}
                                        showModal={this.props.modal}
                                        reset_password_token={this.state.reset_password_token}
                                        reset_password_email={this.state.reset_password_email}
                                    />
                                </>

                            ) : (
                                <Payment
                                    isShow={this.props.modals.payment}
                                    showModal={this.props.modal}
                                    history={history}/>
                            )
                        }
                    </div>
                    <Footer showModal={this.props.modal}/>
                </div>
            </Router>
        );
    }
}

const MapStateToProps = state => ({
    user: state.mainData.user,
    settings: settingsSelector(state),
    preloader: globalPreloader(state),
    modals: modals(state)
});

const MapDispatchToProps = dispatch => ({
    getUser: () => dispatch(getUser()),
    modal: (type, state) => dispatch(modal(type, state)),
    getSettings: () => dispatch(getSettings()),
    showPreloader: state => dispatch(preloader(state)),
});

export default connect(MapStateToProps, MapDispatchToProps)(App);
