import React, {Component} from 'react';
import {connect} from 'react-redux';
import Icon from './../Icon';

import {exportResult} from 'actions/search.actions';

class ExportBlock extends Component {

    constructor(props, context) {
        super(props, context);
        this.exportTable = this.exportTable.bind(this);
    }

    exportTable = (event) => {
        event.preventDefault();
        this.props.exportResult();
    };

    render() {
        return (
            <div className="dropdown float-right">
                <button className="btn btn-primary-2 dropdown-toggle" type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Export
                    <Icon id="dropdown-arrow"/>
                </button>
                <div className="dropdown-menu" x-placement="top-start">
                    <div className="dropdown-content">
                        <a className="dropdown-item" href="#" onClick={event => this.exportTable(event)}>Table (xlsx)</a>
                        <a className="dropdown-item disabled" href="#">Diagram (png)</a>
                    </div>
                </div>
            </div>
        );
    }
}
const MapStateToProps = state => ({});

const MapDispatchToProps = dispatch => ({
    exportResult: () => dispatch(exportResult())
});

export default connect(MapStateToProps, MapDispatchToProps)(ExportBlock);