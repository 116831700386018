import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadFilters, search} from 'actions/search.actions';
import {filtersSelector, positionsSelector} from 'selectors/search.selectors';
import Select from 'react-select';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import numeral from 'numeral';

class ListOfCompanies extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            filters:
                {
                    search: '',
                    sector: '',
                    state: ''
                }
        };
        this.setFilter = this.setFilter.bind(this);
        this.filterCompanies = this.filterCompanies.bind(this);
        this.itemBtnClick = this.itemBtnClick.bind(this);

    }

    componentDidMount() {

    }

    setFilter(name, value) {
        let newFilters = this.state.filters;
        newFilters[name] = value;

        this.setState({filters: newFilters});
    }

    filterCompanies() {
        let companies = this.props.companies;
        let filters = this.state.filters;
        if (Object.keys(filters.sector).length && filters.sector.value)
            companies = companies.filter(item => filters.sector.value === item.sector_id);

        if (Object.keys(filters.state).length && filters.state.value)
            companies = companies.filter(item => filters.state.value === item.state_id);

        if (filters.search.length)
            companies = companies.filter(item => item.name.toLowerCase().includes(filters.search.toLowerCase()));

        return companies;
    }

    itemBtnClick(event, id) {
        this.props.itemBtnClick(id);
        event.preventDefault();
    }

    render() {
        let companies = this.filterCompanies(this.props.companies);
        return (
            <>
            {
                this.props.filters || this.props.search ?
                    (<div className="row">
                        {
                            this.props.filters ?(
                                <div className="col-12 mb-1">
                                    <div className="row">
                                        <div className="col-7">
                                            <Select
                                                name="selected-filter-sector"
                                                value={this.state.filters.sector}
                                                lassName="f-16"
                                                options={this.props.filters.sectors}
                                                onChange={(value) => this.setFilter('sector', value)}
                                            />
                                        </div>
                                        <div className="col-5">
                                            <Select
                                                name="selected-filter-state"
                                                className="f-16"
                                                value={this.state.filters.state}
                                                options={this.props.filters.states}
                                                onChange={(value) => this.setFilter('state', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ): ''
                        }
                        {
                            this.props.search ? (
                                <div className="col-12 mb-1">
                                    <input type="text" className="form-control f-16" placeholder="Search..." style={{borderRadius: 'none'}}
                                           onChange={(event) => this.setFilter('search', event.target.value)}/>
                                </div>
                            ) : ''
                        }
                    </div>) : ''
            }

            <div className="row">
                <div className="col-12">
                    <CustomScroll allowOuterScroll={true}>
                        <div style={{maxHeight: '511px'}}>
                            <ul className="list-group">
                                {
                                    companies.map((item, key) =>
                                        <li key={key}
                                            className={`list-group-item justify-content-between ${(('undefined' !== typeof item.is_base) && item.is_base) ? 'font-weight-bold' : ''}`}>
                                            <span className="company-title">{item.name}</span>
                                            <span className="float-right">
                                                <a href="#" className={`btn btn-sm ${this.props.itemBtnClass}`}
                                                   style={{fontSize: '10px'}}
                                                   onClick={(event) => this.itemBtnClick(event, item.id)}>
                                                    {this.props.itemBtnText}
                                                </a>
                                            </span>
                                        </li>)
                                }

                            </ul>
                        </div>
                    </CustomScroll>

                </div>
            </div>
            </>
        );
    }
}


const MapStateToProps = state => ({});

const MapDispatchToProps = dispatch => ({});

export default connect(MapStateToProps, MapDispatchToProps)(ListOfCompanies);