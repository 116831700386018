import React, {Component} from 'react';
import {connect} from 'react-redux';
import {resultsSelector, selectedFiltersSelector} from 'selectors/search.selectors';
import {removedCompaniesSelector, selectedCompaniesSelector, restoringCompaniesSelector, additionalCompaniesSelector,
    allCompaniesSelector} from 'selectors/search.selectors';
import {search, changeResults} from 'actions/search.actions';
import {addCompany, removeCompany, addCompanyToRestore, removeCompanyFromRestore, restore, addAdditionalCompany,
    removeAdditionalCompany, searchCompanies, setAllCompanies} from 'actions/search.actions';
import {ExportBlock, ListOfCompanies, SalariesTable} from './../components/Results';
import Modal from 'react-bootstrap/Modal';
import numeral from 'numeral';
import {userSelector, settingsSelector} from 'selectors/main.selectors';
import {Redirect} from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';

class Results extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            addCompanyPopup: false,
            searchPreloader: false,
            addAdditionalCompanyPopup: false,
        };
        this.removeCompany = this.removeCompany.bind(this);
        this.addCompany = this.addCompany.bind(this);
        this.addToRestore = this.addToRestore.bind(this);
        this.backToSearch = this.backToSearch.bind(this);
        this.removeFromRestore = this.removeFromRestore.bind(this);
        this.restore = this.restore.bind(this);
        this.addAdditional = this.addAdditional.bind(this);
        this.removeAdditional = this.removeAdditional.bind(this);

        this.addAdditionalDone = this.addAdditionalDone.bind(this);
        this.removeAdditionalAndResearch = this.removeAdditionalAndResearch.bind(this);
    }

    componentDidMount() {
        searchCompanies('').then(companies => {
            this.props.setAllCompanies(companies);
            this.forceUpdate();
        });
    }

    removeCompany(id) {
        if('professional' !== this.props.user.current_plan)
            return false;
        this.setState({searchPreloader: true});
        this.props.removeCompany(id);
        this.props.changeResults().finally(() => this.setState({searchPreloader: false}));
    }

    addCompany(id) {
        if('professional' !== this.props.user.current_plan)
            return false;
        this.props.addCompany(id);
    }

    addToRestore(id) {
        if('professional' !== this.props.user.current_plan)
            return false;
        this.props.addToRestore(id);
    }

    removeFromRestore(id) {
        if('professional' !== this.props.user.current_plan)
            return false;
        this.props.removeFromRestore(id);
    }

    addAdditional(id) {
        this.props.addAdditional(id);
        this.forceUpdate();
    }

    removeAdditional(id) {
        this.props.removeAdditional(id);
        this.forceUpdate();
    }

    backToSearch(event){
        this.props.history.push('/search');
        event.preventDefault();
    }

    restore(){
        if('professional' !== this.props.user.current_plan)
            return false;

        this.setState({searchPreloader: true});
        this.props.restore();
        this.setState({addCompanyPopup: false});
        this.props.changeResults().finally(() => this.setState({searchPreloader: false}));
    }

    addAdditionalDone(){
        if('professional' !== this.props.user.current_plan)
            return false;
        this.setState({searchPreloader: true, addAdditionalCompanyPopup: false});
        this.props.changeResults().finally(() => this.setState({searchPreloader: false}));
    }

    removeAdditionalAndResearch(id){
        this.removeAdditional(id);
        this.addAdditionalDone();
    }


    render() {
        let {results, selectedCompanies, removedCompanies, restoringCompanies, additionalCompanies, allCompanies} = this.props;
        if (!Object.keys(results).length)
            return (<Redirect to='/search' />);

        let filters = {
            sectors: [{value: '', label: 'All'}].concat(results.sectors.map(item => ({value: item.id, label: item.name}))),
            states: [{value: '', label: 'All'}].concat(results.states.map(item => ({value: item.id, label: item.name})))
        };

        let additionalAll = [];
        let additionalSelected = [];
        if(allCompanies) {
            additionalAll = allCompanies.filter(item => !additionalCompanies.includes(item.id));
            additionalSelected = allCompanies.filter(item => additionalCompanies.includes(item.id));
        }
        return (
            <>
            <ReactTitle title={`Results | ${this.props.settings.app_name} - Executive Compensation Benchmarking`}/>
                <section className="bg-primary-3 header-inner p-0 jarallax position-relative o-hidden">
                    <div className="container py-0 layer-2">
                        <div className="row my-3 aos-init aos-animate" data-aos="fade-up">
                            <div className="col-lg-9 col-xl-6">
                                <h2 className="display-4">Results</h2>
                            </div>
                        </div>
                    </div>
                </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            <table className="table table-bordered table-salaries">
                                <thead>
                                    <tr>
                                        <td style={{width: '30%'}}>Positions</td>
                                        <td>{this.props.selectedFilters.position.map(item => item.label).join(', ')}</td>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                        {
                            ('undefined' !== typeof this.props.selectedFilters.year)?
                                <div className="col-4">
                                    <table className="table table-bordered table-salaries">
                                        <thead>
                                        <tr>
                                            <td>Year</td>
                                            <td>{this.props.selectedFilters.year.value}</td>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            : ''
                        }

                    </div>
                    <div className="row">
                        <div className="col-10">
                            <h3>{this.props.selectedFilters.position.label}</h3>
                        </div>
                        <div className={"col-2" + (('professional' !== this.props.user.current_plan) ? ' disabled-block' : '')}>
                            <ExportBlock/>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col">
                                <p>{this.props.selectedFilters.position.description}</p>
                            </div>
                        </div>
                        <div className={"row" + (this.state.searchPreloader ? ' preloader' : '')}>
                            <div className="col-xl-8 col-12 overflow-auto">
                                <div className="row">
                                    <div className="overflow-auto col-12">
                                        <SalariesTable results={this.props.results}/>
                                    </div>

                                    <div className="col-12">
                                        <h4>Peer group features</h4>
                                        <table className="table table-bordered table-salaries">
                                            <thead>
                                            <tr>
                                                <td style={{width: '30%'}}/>
                                                <td style={{width: '35%'}}>Min</td>
                                                <td style={{width: '35%'}}>Max</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Revenue (MUSD)</td>
                                                <td>{numeral(this.props.results.revenue.min).format('0,0.0')}</td>
                                                <td>{numeral(this.props.results.revenue.max).format('0,0.0')}</td>
                                            </tr>
                                            <tr>
                                                <td>Headcount (employees)</td>
                                                <td>{numeral(this.props.results.headcount.min).format('0,0')}</td>
                                                <td>{numeral(this.props.results.headcount.max).format('0,0')}</td>
                                            </tr>
                                            <tr>
                                                <td>Market Cap (MUSD)</td>
                                                <td>{numeral(this.props.results.market_cap.min).format('0,0.0')}</td>
                                                <td>{numeral(this.props.results.market_cap.max).format('0,0.0')}</td>
                                            </tr>
                                            {
                                                ('professional' === this.props.user.current_plan)
                                                    ? (
                                                        <>
                                                            <tr>
                                                                <td>Sectors</td>
                                                                <td colSpan="2">{this.props.results.sectors.map(item => item.name).join(', ')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Locations</td>
                                                                <td colSpan="2">{this.props.results.states.map(item => item.name).join(', ')}</td>
                                                            </tr>
                                                        </>
                                                    ) : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-12 col-xl-4 col-12" + (('professional' !== this.props.user.current_plan) ? ' disabled-block' : '')}>
                                <h4>Selected Companies</h4>
                                <ListOfCompanies companies={selectedCompanies}
                                                 filters={filters}
                                                 search={true}
                                                 itemBtnClass="btn-outline-danger add-btn"
                                                 itemBtnText="remove"
                                                 itemBtnClick={this.removeCompany}
                                />
                                <br/>
                                {
                                    removedCompanies.length || restoringCompanies.length ? (
                                        <div className="row">
                                            <div className="col text-center">
                                                <button className="btn btn-sm btn-success form-control btn-primary-2" onClick={() => this.setState({addCompanyPopup: true})}>
                                                    Restore Companies
                                                </button>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                <br/>
                                <br/>
                                <h4>Additional Companies</h4>
                                <ListOfCompanies companies={additionalSelected}
                                                 filters={false}
                                                 search={false}
                                                 itemBtnClass="btn-outline-danger add-btn"
                                                 itemBtnText="remove"
                                                 itemBtnClick={this.removeAdditionalAndResearch}
                                />
                                <br/>

                                {
                                    ('professional' === this.props.user.current_plan) ?
                                        <div className="row">
                                            <div className="col text-center">
                                                <button className="btn btn-sm btn-success form-control btn-primary-2" onClick={() => this.setState({addAdditionalCompanyPopup: true})}>
                                                    Add Additional Company
                                                </button>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-12 mt-1 text-right">
                                <button className="btn btn-primary-2 btn_size_1 " onClick={this.backToSearch}>Back To Search</button>
                            </div>
                        </div>
                </div>
            </section>

            {
                ('professional' === this.props.user.current_plan)
                    ? (
                        <Modal show={this.state.addCompanyPopup} onHide={() => this.setState({addCompanyPopup: false})} size="lg">
                            <Modal.Body>
                                <div className="m-xl-4 m-3">
                                    <div className="text-center mb-4">
                                        <h4 className="h3 mb-1">Restore Companies</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h6>Removed</h6>
                                            <ListOfCompanies companies={removedCompanies}
                                                             filters={false}
                                                             itemBtnClass="btn-outline-primary-2 add-btn"
                                                             itemBtnText="restore"
                                                             itemBtnClick={this.addToRestore}
                                            />
                                        </div>
                                        <div className="col">
                                            <h6>Restored</h6>
                                            <ListOfCompanies companies={restoringCompanies}
                                                             filters={false}
                                                             itemBtnClass="btn-outline-danger add-btn"
                                                             itemBtnText="don't restore"
                                                             itemBtnClick={this.removeFromRestore}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-sm btn-success form-control btn-primary-2 btn_size_2" onClick={this.restore}>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    ) : null
            }

                {
                    ('professional' === this.props.user.current_plan)
                        ? (
                            <Modal show={this.state.addAdditionalCompanyPopup} onHide={() => this.setState({addAdditionalCompanyPopup: false})} size="lg">
                                <Modal.Body>
                                    <div className="m-xl-4 m-3">
                                        <div className="text-center mb-4">
                                            <h4 className="h3 mb-1">Add Additional Companies</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ListOfCompanies companies={additionalAll}
                                                                 filters={false}
                                                                 search={true}
                                                                 itemBtnClass="btn-outline-primary-2 add-btn"
                                                                 itemBtnText="add"
                                                                 itemBtnClick={this.addAdditional}
                                                />
                                            </div>
                                            <div className="col">
                                                <ListOfCompanies companies={additionalSelected}
                                                                 filters={false}
                                                                 search={false}
                                                                 itemBtnClass="btn-outline-danger add-btn"
                                                                 itemBtnText="remove"
                                                                 itemBtnClick={this.removeAdditional}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-sm btn-success form-control btn-primary-2 btn_size_2" onClick={this.addAdditionalDone}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        ) : null
                }
            </>
        );
    }
}

const MapStateToProps = state => ({
    results: resultsSelector(state),
    selectedCompanies: selectedCompaniesSelector(state),
    removedCompanies: removedCompaniesSelector(state),
    restoringCompanies: restoringCompaniesSelector(state),
    additionalCompanies: additionalCompaniesSelector(state),
    user: userSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    settings: settingsSelector(state),
    allCompanies: allCompaniesSelector(state),
});

const MapDispatchToProps = dispatch => ({
    removeCompany: id => dispatch(removeCompany(id)),
    addCompany: id => dispatch(addCompany(id)),
    addToRestore: id => dispatch(addCompanyToRestore(id)),
    removeFromRestore: id => dispatch(removeCompanyFromRestore(id)),
    restore: () => dispatch(restore()),
    addAdditional: id => dispatch(addAdditionalCompany(id)),
    removeAdditional: id => dispatch(removeAdditionalCompany(id)),
    recalculate: id => dispatch(search('for_a_company', {}, false)),
    search: id => dispatch(search('for_a_company')),
    changeResults: () => dispatch(changeResults()),
    setAllCompanies: (companies) => dispatch(setAllCompanies(companies))
});

export default connect(MapStateToProps, MapDispatchToProps)(Results);
