import API_URLS from './apiConfig';
import serializeParams from './serializeParams';
import contentType from 'content-type';

const defaultFetchOptions = {
    method: 'get',
    node: 'cors',
    headers: {
        Accept: 'application/json'
    },
    credentials: 'same-origin'
};

class Http {
    constructor() {
        this.options = defaultFetchOptions;
        this.setCSRFToken();
        this.setAuthToken();

    }

    setCSRFToken() {
        let token = document.head.querySelector('meta[name="csrf-token"]');
        if (!token)
            return false;

        this.options.headers['X-CSRF-TOKEN'] = token.content;
    }

    setAuthToken() {
        if ('undefined' === typeof localStorage)
            return false;
        if (!localStorage.devBetterAuth)
            return delete this.options.headers['Authorization'];

        this.options.headers['Authorization'] = `Bearer ${localStorage.devBetterAuth}`;
    }


    getUrlFromType(type) {
        const url = API_URLS[type];
        if (!url) {
            throw new Error(`Type "${type}" is not defined in config. Please check the params.`);
        }

        return url;
    }

    getQuery(type, params) {

        return `${this.getUrlFromType(type)}?${serializeParams(params)}`;
    }

    fetch(type, url, params, options, success, error) {
        const query = this.getQuery(url, params);
        let opt = Object.assign({}, this.options, options, {method: type});

        fetch(query, opt)
            .then((response) => {
                let cType= contentType.parse( response.headers.get('Content-Type'));
                if('application/json' !== cType.type)
                    return response.blob();

                if ((response.status === 400 || response.status === 401)) {
                    response.json()
                        .then((result) => {
                            error({block: {[result.error]: result.message}});
                        })
                        .catch(console.error);
                } else {
                    if (response.status === 422) {
                        response.json()
                            .then((result) => {
                                error({fields: result.errors, block: {invalid_data: result.message}});
                            })
                            .catch(console.error);
                    }
                    else if (response.status === 423) {
                        response.json()
                            .then((result) => {
                                error(result);
                            })
                            .catch(console.error);
                    } else {
                        response.json()
                            .then((result) => {
                                if (response.status === 404) {
                                    result.statusCode = 404;
                                    error(result);
                                } else if(response.status === 500) {
                                    error(result);
                                }else{
                                    success(result);
                                }
                            })
                            .catch(console.error);
                    }
                }

            }).then(function (myBlob) {
                if (myBlob instanceof Blob) {
                    const blob = new Blob([myBlob]);
                    console.log(blob);
                    success(blob);
                }
            })
            .catch((err) => {
                console.error('my error: ', err);
            })
    }

    query(type, url, params, options) {
        return new Promise((resolve, reject) => {
            this.fetch(type, url, params, options, resolve, reject)
        });
    }

    get(url, params = {}, options = {}) {
        return this.query('get', url, params, options)
    }

    post(url, params = {}, options = {}) {
        return this.query('post', url, params, options)
    }

    put(url, params = {}, options = {}) {
        return this.query('put', url, params, options)
    }

    remove(url, params = {}, options = {}) {
        return this.query('delete', url, params, options)
    }

}

export default new Http();