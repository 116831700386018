import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logout} from 'actions/main.actions';
import {userSelector, variablesSelector} from 'selectors/main.selectors';
import Icon from './../Icon';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        const variables = this.props.variables;
        const mail = variables.footer_email || 'hello@company.io';
        return (
            <footer className="pb-3 pt-3 bg-primary-3 text-light" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg">
                            <Link to="/" className="navbar-brand fade-page pb-3">
                                <img width={50} src="/img/logo-footer.png" alt="MK"/>
                            </Link>
                            <ul className="list-unstyled">
                                <li className="mb-3 d-flex">
                                    <Icon id="address"/>
                                    <div className="ml-3">
                                      <span>{variables.footer_address || '348 Greenpoint Avenue Brooklyn, NY'}</span>
                                    </div>
                                </li>

                                <li className="mb-3 d-flex">
                                    <Icon id="email"/>
                                    <div className="ml-3">
                                        <a href={`mailto:${mail}`}>{mail}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>


                </div>
            </footer>
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    variables: variablesSelector(state)
});

const MapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(MapStateToProps, MapDispatchToProps)(Footer);

