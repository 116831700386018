import Http from 'helpers/fetch';
import {preloader} from 'main.actions';
import {selectedFiltersSelector, selectedCompaniesSelector, resultIdSelector, additionalCompaniesSelector} from 'selectors/search.selectors';
import FileSaver from 'file-saver';

const setFilters = (filters) => {
    return {
        type: 'SET_FILTERS',
        filters: filters
    }
};

export function loadFilters(filters = '', allLoaded = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(preloader(true));
            Http.get('getFilters', {filters: filters}).then(response => {
                dispatch(setFilters(response));
                if(allLoaded) {
                    dispatch(changeFilter('revenue', response.revenue));
                    dispatch(changeFilter('market_cap', response.market_cap));
                    dispatch(changeFilter('headcount', response.headcount));

                    dispatch({type: 'SET_ALL_LOADED'});
                }
                resolve(response);
                dispatch(preloader(false));
            }).catch(error => {

            });
        });
    }
}

const setResults = (results) => {
    return {
        type: 'SET_RESULTS',
        results: results
    }
};
const setSelectedCompanies = (companies) => {
    return {
        type: 'SET_SELECTED_COMPANIES',
        companies: companies
    }
};

export const removeCompany = id =>
    ({type: 'REMOVE_COMPANY', id: id});

export function addCompany(id){
    return {type: 'ADD_COMPANY', id: id};
}


export function addCompanyToRestore(id){
    return {type: 'ADD_COMPANY_TO_RESTORING', id: id};
}

export function removeCompanyFromRestore(id){
    return {type: 'REMOVE_COMPANY_FROM_RESTORING', id: id};
}

export function restore(){
    return {type: 'RESTORE_COMPANIES'};
}

export function setAllCompanies(companies){
    return {type: 'SET_ALL_COMPANIES', companies};
}

export function addAdditionalCompany(id){
    return {type: 'ADD_ADDITIONAL_COMPANY', id};
}

export function removeAdditionalCompany(id){
    return {type: 'REMOVE_ADDITIONAL_COMPANY', id};
}



export const changeFilter = (name, value) =>
    ({type: 'SET_SELECTED_FILTER', name: name, value: value});

export function search(type, onlyCount = false, updateSelected = true) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            let filters = {};
            let selected = selectedFiltersSelector(getState());

            filters.search_type = type;
            filters.count = onlyCount;

            if(('undefined' !== typeof selected.position) && selected.position)
                filters.position = selected.position.map(item => item.value);

            if(('undefined' !== typeof selected.year) && selected.year)
                filters.year = selected.year.value;
            else
                filters.year = 2019;

            switch (type){
                case 'for_a_company':
                    if(('undefined' !== typeof selected.company) && selected.company)
                        filters.company = selected.company.value;
                    break;
                case 'customize_benchmark':
                    if(('undefined' !== typeof selected.sector) && selected.sector)
                        filters.sectors = selected.sector.map(item => item.value);
                    if(('undefined' !== typeof selected.state) && selected.state)
                        filters.states = selected.state.map(item => item.value);

                    if(('undefined' !== typeof selected.market_cap) && selected.market_cap)
                        filters.market_cap = selected.market_cap;
                    if(('undefined' !== typeof selected.headcount) && selected.headcount)
                        filters.headcount = selected.headcount;
                    if(('undefined' !== typeof selected.revenue) && selected.revenue)
                        filters.revenue = selected.revenue;
                    break;
                default:
            }

            formData.append('filters', JSON.stringify(filters));
            Http.post('search', {}, {body: formData}).then(response => {
                dispatch(setResults(response));
                if(updateSelected)
                    dispatch(setSelectedCompanies(response.companies));
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export function searchCompanies(search){

    return new Promise((resolve, reject) => {
        Http.get('searchCompanies', {search}).then(response => {
            resolve(response);
        }).catch(error => {
        });
    });
}

export function changeResults(){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let companies = selectedCompaniesSelector(getState()).map(item => item.id);
            let result_id = resultIdSelector(getState());
            const additionalCompanies = additionalCompaniesSelector(getState());

            const formData = new FormData();
            formData.append('companies', JSON.stringify(companies));
            formData.append('result_id', result_id);
            formData.append('additional_companies', JSON.stringify(additionalCompanies));

            Http.post('changeResults', {}, {body: formData}).then(response => {
                dispatch(setResults(response));
                resolve(response);
            }).catch(error => {

            });
        });
    }
}

export function getAllCompanies(){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let companies = selectedCompaniesSelector(getState()).map(item => item.id);
            const formData = new FormData();
            formData.append('exclude', JSON.stringify(companies));
            Http.post('allCompanies', {}, {body: formData}).then(response => {
                dispatch(setResults(response));
                resolve(response);
            }).catch(error => {

            });
        });
    }
}

export function exportResult(){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let result_id = resultIdSelector(getState());
            let companies = selectedCompaniesSelector(getState()).map(item => item.id);

            const formData = new FormData();
            formData.append('result_id', result_id);

            Http.post('exportResults', {}, {body: formData})
                .then((response) => {
                    FileSaver.saveAs(response, 'export.xlsx');
                })
                .catch((error) => {

                });
        });
    }
}