import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logout} from 'actions/main.actions';
import {userSelector} from 'selectors/main.selectors';
import {Link} from 'react-router-dom';
import {TOGGLE_NAV} from 'reducers/main.reducers';

class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showMobileNav: false
        };
    }

    render() {
        return (
            <div className="navbar-container">
                <nav className="navbar navbar-expand-lg navbar-dark bg-primary-3" data-sticky="top">
                    <div className="container">
                        <Link to="/" className="navbar-brand fade-page">
                            <img width={120} src="/img/logo.png" alt="MK"/>
                        </Link>
                        <button className="navbar-toggler" type="button" onClick={this.props.toggleNav}>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className={`collapse navbar-collapse justify-content-end  ${this.props.showMobileNav?'show':''}`}>
                            <div className="py-2 py-lg-0">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/our-service" className="nav-link">Our Service</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/pricing" className="nav-link">Pricing</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/about" className="nav-link">About</Link>
                                    </li>
                                    {
                                        this.props.user ? (
                                            <>
                                                <li className="nav-item">
                                                    <a href="/get-started"  className="nav-link">Get Started</a>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/search" className="nav-link">Search</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#" onClick={this.props.logout} className="nav-link">Sign Out</a>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="nav-item">
                                                    <a href="#" onClick={() => this.props.showModal('register')} className="nav-link">Sign Up</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#" onClick={() => this.props.showModal('login')} className="nav-link">Log In</a>
                                                </li>
                                            </>
                                        )
                                    }
                                </ul>
                            </div>
                            <Link to="/pricing" className="btn btn-primary-2 ml-lg-3 purchase">Purchase Now</Link>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    showMobileNav: state.mainData.showMobileNav
});

const MapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    toggleNav: () => dispatch({type: TOGGLE_NAV})
});

export default connect(MapStateToProps, MapDispatchToProps)(Header);

