export default {
    login: `${process.env.API_HOST}/oauth/token`,
    register: `${process.env.API_HOST}/oauth/register`,
    forgot_password: `${process.env.API_HOST}/api/password/email`,
    change_password: `${process.env.API_HOST}/api/password/reset`,
    user: `${process.env.API_HOST}/api/user`,
    pay: `${process.env.API_HOST}/api/pricing-plan`,
    getFilters: `${process.env.API_HOST}/api/filters`,
    searchCompanies: `${process.env.API_HOST}/api/companies`,
    search: `${process.env.API_HOST}/api/search`,
    changeResults: `${process.env.API_HOST}/api/results`,
    exportResults: `${process.env.API_HOST}/api/export`,
    settings: `${process.env.API_HOST}/api/settings`,
    page: `${process.env.API_HOST}/api/page`,
    message: `${process.env.API_HOST}/api/message`
};

