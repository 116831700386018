const defaultState = {
    filters: {
        positions: [],
        companies: [],
        sectors: [],
        states: [],
        years: [],
        all_is_loaded: false
    },
    selectedFilters: {
        position: null,
        company: []
    },
    results: {},
    allCompanies: [],
    additionalCompanies: []
};

export function searchData(state = defaultState, action) {
    switch (action.type) {
        case 'SET_FILTERS': {
            return {
                ...state,
                filters: {...state.filters, ...action.filters}
            }
        }
        case 'SET_ALL_LOADED': {
            return {
                ...state,
                filters: {...state.filters, all_is_loaded: true}
            }
        }
        case 'SET_SELECTED_FILTER': {
            return {
                ...state,
                selectedFilters: {...state.selectedFilters, [action.name]: action.value}
            }
        }

        case 'SET_RESULTS': {
            return {
                ...state,
                results: {...state.results, ...action.results}
            }
        }
        case 'SET_SELECTED_COMPANIES': {
            return {
                ...state,
                selectedCompanies: action.companies
            }
        }
        case 'REMOVE_COMPANY': {
            let results = Object.assign({},  state.results);
            let company = results.companies.filter(item => item.id === action.id)[0];
            company.is_removed = true;
            company.is_restoring = false;

            return {
                ...state,
                results: results,
            }
        }
        case 'ADD_COMPANY': {
            let results = Object.assign({},  state.results);
            let company = results.companies.filter(item => item.id === action.id)[0];
            company.is_removed = false;

            return {
                ...state,
                results: results,
            }
        }

        case 'ADD_COMPANY_TO_RESTORING': {
            let results = Object.assign({},  state.results);
            let company = results.companies.filter(item => item.id === action.id)[0];
            company.is_restoring = true;
            return {
                ...state,
                results: results,
            }
        }

        case 'REMOVE_COMPANY_FROM_RESTORING': {
            let results = Object.assign({},  state.results);
            let company = results.companies.filter(item => item.id === action.id)[0];
            company.is_restoring = false;

            return {
                ...state,
                results: results,
            }
        }

        case 'RESTORE_COMPANIES': {
            let results = Object.assign({},  state.results);
            let companies = results.companies.filter(item =>  item.is_restoring);
            companies.map(item => {
                item.is_restoring = false;
                item.is_removed = false;
            });
            return {
                ...state,
                results: results,
            }
        }

        case 'ADD_ADDITIONAL_COMPANY': {
            const companies = state.additionalCompanies;
            companies.push(action.id);
            return {...state, additionalCompanies: companies};
        }
        case 'REMOVE_ADDITIONAL_COMPANY': {
            const companies = state.additionalCompanies.filter(item => item !== action.id);
            return {...state, additionalCompanies: companies};
        }

        case 'SET_ALL_COMPANIES': {
            console.log(action);
            return {...state, allCompanies: action.companies}
        }

        default:
            return state;
    }
}