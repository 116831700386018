import { createSelector } from 'reselect';

const mainDataSelector = state => state.mainData;

export const modalsSelector = createSelector(
        mainDataSelector,
        data => data.modals
);

export const userSelector = createSelector(
    mainDataSelector,
    data => data.user
);

export const settingsSelector = createSelector(
    mainDataSelector,
    data => data.settings
);

export const countriesSelector = createSelector(
    settingsSelector,
    data => data.countries.map(item => ({value: item.code, label: item.name}))
);

export const variablesSelector = createSelector(
    settingsSelector,
    data => data.variables || {}
);

export const appNameSelector = createSelector(
    settingsSelector,
    data => data.app_name || {}
);

export const pricingPlansSelector = createSelector(
    settingsSelector,
    data => {
        let plans = {};
        if('undefined' === typeof data.pricing_plans)
            return null;

        data.pricing_plans.forEach(item => {
            plans[item.system_name] = item;
        });

        return plans;
    }
);


export const errorsSelector = createSelector(
    mainDataSelector,
    data => data.errors
);

export const selectedPricingPlan = createSelector(
    mainDataSelector,
    data => data.selectedPricingPlan
);

export const globalPreloader = createSelector(
    mainDataSelector,
    data => data.globalPreloader
);

export const modals = createSelector(
    mainDataSelector,
    data => data.modals
);


export const filteredErrors = createSelector(
    errorsSelector,
    (errors) => (group, type = null) => {
        if('undefined' === typeof errors[group])
            return null;

        if(!errors[group])
            return null;

        if(!type)
            return errors[group];

        return errors[group][type];
    }
);