import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changePassword, login, modal, preloader} from 'actions/main.actions';
import {filteredErrors, modalsSelector} from 'selectors/main.selectors';
import Base from './Base';

class ChangePassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            password: '',
            password_confirmation: '',
            password_changed: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.preloader(true);
        const {password, password_confirmation} = this.state;
        this.props.changePassword(this.props.reset_password_token, this.props.reset_password_email, password, password_confirmation).then(() => {
            this.setState({password_changed: true});
            this.props.preloader(false);
            this.props.history.push('/');
        }).finally(() => this.props.preloader(false)).catch(()=>{

        });
    }

    render() {
        let errors = this.props.errors;
        return (
            <Base {...this.props} modalId="change_password">
                <div className="text-center mb-4">
                    <h4 className="h3 mb-1">Change Password</h4>
                </div>
                {
                    !this.state.password_changed ?
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <input type="text" name="email" placeholder="Email" readOnly={true}
                                       className="form-control" value={this.props.reset_password_email}/>
                            </div>
                            <div className="form-group">
                                <input type="password" name="password" placeholder="New Password"
                                       className="form-control" value={this.state.password} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <input type="password" name="password_confirmation" placeholder="New Password Confirmation"
                                       className="form-control" value={this.state.password_confirmation} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <button className="btn-block btn btn-primary-2" type="submit">
                                    Change Password
                                </button>
                                {
                                    errors ? (
                                        <div className="text-red mt-2">
                                            {
                                                Object.keys(errors).map((key) => (
                                                    <p key={key}>{errors[key]}</p>
                                                ))
                                            }

                                        </div>
                                    ) : ''
                                }
                            </div>
                        </form>
                        :
                        <div className="text-center">
                            Password was changed. <a href="#" onClick={() => this.props.modal('login', true)}>Sign In</a>
                        </div>
                }
            </Base>
        );
    }
}


const MapStateToProps = state => ({
    errors: filteredErrors(state)('change_password', 'block')
});

const MapDispatchToProps = dispatch => ({
    preloader: state => dispatch(preloader(state)),
    changePassword: (token, email, password, password_confirmation) => dispatch(changePassword(token, email, password, password_confirmation)),
    modal: (type, state) => dispatch(modal(type, state)),
});

export default connect(MapStateToProps, MapDispatchToProps)(ChangePassword);