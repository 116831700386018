import Http from 'helpers/fetch';
import {errorsSelector, filteredErrors, selectedPricingPlan} from 'selectors/main.selectors';

const showModal = (modal) => {
    return {
        type: 'SHOW_MODAL',
        modal: modal
    }
};

const closeModal = (modal) => {
    return {
        type: 'CLOSE_MODAL',
        modal: modal
    }
};

const setUser = (user) => {
    return {
        type: 'SET_USER',
        user: user
    }
};

export const setUserField = (field, value) => ({
    type: 'SET_USER_FIELD',
    field, value
});

export function modal(name, state = true) {
    return (dispatch, getState) => {
        if (state) {
            dispatch(showModal(name));
        } else {
            dispatch(closeModal(name));
        }
    }
}

export function login(email, password) {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append(`username`, email);
            formData.append(`password`, password);
            formData.append(`client_id`, process.env.CLIENT_ID);
            formData.append(`client_secret`, process.env.CLIENT_SECRET);
            formData.append(`grant_type`, 'password');
            Http.post('login', {}, {body: formData})
                .then((response) => {
                    if (response.access_token) {
                        storeAuth(response.access_token);
                        dispatch(getUser()).then(() => {
                            resolve({success: true});
                        });
                    }
                })
                .catch((error) => {
                    dispatch(setError('login', error));
                    reject(error);
                });
        });

    };
}

export function forgotPassword(email) {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append(`email`, email);
            formData.append(`client_id`, process.env.CLIENT_ID);
            formData.append(`client_secret`, process.env.CLIENT_SECRET);
            formData.append(`grant_type`, 'password');
            Http.post('forgot_password', {}, {body: formData})
                .then((response) => {
                    resolve({success: true});
                })
                .catch((error) => {
                    dispatch(setError('forgot_password', error));
                    reject(error);
                });
        });

    };
}

export function changePassword(token, email, password, password_confirmation) {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append(`email`, email);
            formData.append(`token`, token);
            formData.append(`password`, password);
            formData.append(`password_confirmation`, password_confirmation);
            formData.append(`client_id`, process.env.CLIENT_ID);
            formData.append(`client_secret`, process.env.CLIENT_SECRET);
            formData.append(`grant_type`, 'password');
            Http.post('change_password', {}, {body: formData})
                .then((response) => {
                    resolve({success: true});
                })
                .catch((error) => {
                    dispatch(setError('change_password', error));
                    reject(error);
                });
        });

    };
}

export function register(email, password, password_confirmation) {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append(`username`, email);
            formData.append(`password`, password);
            formData.append(`password_confirmation`, password_confirmation);

            formData.append(`client_id`, process.env.CLIENT_ID);
            formData.append(`client_secret`, process.env.CLIENT_SECRET);
            formData.append(`grant_type`, 'password');
            Http.post('register', {}, {body: formData})
                .then((response) => {
                    if (response.access_token) {
                        storeAuth(response.access_token);
                        dispatch(getUser()).then(() => {
                            resolve({success: true});
                        });
                    }
                })
                .catch((error) => {
                    dispatch(setError('register', error));
                    reject(error);
                });
        });
    };
}

export function getUser() {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            Http.get('user')
                .then((response) => {
                    dispatch(setUser(response));
                    resolve(response);
                })
                .catch((error) => {
                    dispatch(logout());
                    reject(error);
                });
        });

    };
}

export function getPage(system_name) {
    return new Promise((resolve, reject) => {
        Http.get('page', {system_name: system_name})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function storeAuth(token) {
    if (typeof localStorage !== 'undefined')
        localStorage.setItem('devBetterAuth', token);

    Http.setAuthToken();
}

export function preloader(status = true) {
    return {type: 'SET_GLOBAL_PRELOADER', status};
}

export function logout() {
    return (dispatch, getStore) => {
        if (typeof localStorage !== 'undefined')
            localStorage.removeItem('devBetterAuth');

        Http.setAuthToken();
        dispatch(setUser(null));
        dispatch(modal('login'));
    }
}

export function setError(group, error, type = null) {
    return (dispatch, getStore) => {
        let errors = {};
        if (error) {
            errors = filteredErrors(getStore())(group) || {};
            if (type) {
                errors[type] = Object.assign(errors[type] || {}, error);
            } else {
                errors = Object.assign(errors || {}, error);
            }
        }

        dispatch({type: 'SET_ERROR', group: group, errors: errors});
    }
}

export function checkAuth() {
    if ('undefined' === typeof localStorage)
        return false;
    if (!localStorage.devBetterAuth)
        return false;

    return true;
}

export function setPricingPlan(plan) {
    return (dispatch, getState) => {
        dispatch({type: 'SET_SELECTED_PRICING_PLAN', plan: plan});
    }
}

export function pay(token, meta) {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            let plan = selectedPricingPlan(getStore());
            const formData = new FormData();
            formData.append('plan', plan.system_name);
            formData.append('token', token);
            formData.append('meta', JSON.stringify(meta));

            Http.post('pay', {}, {body: formData})
                .then((response) => {
                    dispatch(setUser(response.user));
                    resolve(response);
                })
                .catch((error) => {
                    dispatch(setError('payment', error));
                    reject(error);
                });
        });
    }
}

export function getSettings() {
    return (dispatch, getStore) => {
        return new Promise((resolve, reject) => {
            Http.get('settings')
                .then((response) => {
                    dispatch({type: 'SET_SETTINGS', settings: response});
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function message(data) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        for (const field in data) {
            if (data.hasOwnProperty(field))
                formData.append(field, data[field]);
        }
        Http.post('message', {}, {body: formData})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

