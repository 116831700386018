import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectStripe} from 'react-stripe-elements';
import {CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import {userSelector, filteredErrors, selectedPricingPlan, countriesSelector} from 'selectors/main.selectors';
import {pay, setError} from 'actions/main.actions';
import Select from "react-select";
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css'
import 'react-toastify/dist/ReactToastify.min.css';

class StripeForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            enablePayButton: true,
            email: '',
            name: '',
            country: '',
            phone: '',
            address_line1: '',
            company_name: '',
            vat: '',
            showValidationErrors: false,
            paid: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.afterPayment = this.afterPayment.bind(this);



    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        if(('' === this.state.name) || ('' === this.state.country) || '' === this.state.address_line1)
            return this.setState({showValidationErrors: true});

        this.setState({enablePayButton: false, showValidationErrors: false});
        this.setState({error: false});
        let self = this;
        const data = {
            name: this.state.name,
            address_country: this.state.country.label,
            address_line1: this.state.address_line1,
        };

        const meta = {
            company_name: this.state.company_name,
            vat: this.state.vat,
            phone: this.state.phone
        };

        this.props.stripe.createToken(data).then(({token}) => {
            this.props.setError(null);
            if('undefined' === typeof token) {
                this.props.setError({invalid_card: 'Please enter correct payment data'});
                self.setState({enablePayButton: true});
                return true;
            }

            this.props.pay(token.id, meta).then((response) => {
                this.setState({
                    paid: true
                });
            }).catch((error) => {
                self.setState({enablePayButton: true});
            });
        });
    };

    handleChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        this.props.setError(null);
    };

    handleChangeSelect(name, value){

        this.setState({
            [name]: value
        });

        this.props.setError(null);
    };

    handleChangePhone(value) {
        this.setState({
            phone: value
        });
    }
    createStyle(){
        return {
            base: {
                fontSize: '14px',
                paddingTop: '12px',
            }
        };
    }

    afterPayment(){
        this.props.showModal('payment', 0);
        this.props.history.push('/search');
    }

    render() {
        let errors = this.props.errors;
        const colourStyles = {
            control: styles => ({ ...styles, border: '1px solid #dee2e6' }),
            placeholder: styles => ({ ...styles, color: '#b3b8bd'}),
        };
        return (
            <>
                {
                    this.state.paid
                        ? (
                            <div>
                                <div className="alert alert-success text-center mb-4" role="alert">
                                    Your plan has been updated.
                                </div>
                                <p className="text-center">
                                    <button className="btn btn-primary-2" onClick={this.afterPayment}>Get Started</button>
                                </p>
                            </div>
                        ) : (
                            <form onSubmit={this.handleSubmit}>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <h5>Card Info</h5>
                                    </div>
                                </div>
                                <div className="row mb-2 form-group card-field">
                                    <div className="col-12">
                                        <CardNumberElement style={this.createStyle()} placeholder="Card Number" className="form-control" />
                                    </div>
                                </div>
                                <div className="row form-group card-field">
                                    <div className="col-5">
                                        <CardExpiryElement className="form-control"/>
                                    </div>
                                    <div className="col-4 offset-3">
                                        <CardCVCElement className="form-control" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <h5>Billing Info</h5>
                                    </div>
                                </div>

                                <div className="row mb-2 form-group">
                                    <div className="col-12">
                                        <input onChange={this.handleChange} placeholder="Name" className="form-control" name="name" value={this.state.name} required={true} />
                                    </div>
                                </div>
                                <div className="row mb-2 form-group">
                                    <div className="col-12">
                                        <ReactPhoneInput
                                            onChange={this.handleChangePhone}
                                            value={this.state.phone}
                                            placeholder="Phone"
                                            inputExtraProps={{
                                                name: 'phone',
                                                className: "form-control",
                                                style: {width: '100%'}
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2 form-group">
                                    <div className="col-12">
                                        <Select
                                            name="country"
                                            value={this.state.country}
                                            options={this.props.countries}
                                            onChange={(value) => this.handleChangeSelect('country', value)}
                                            placeholder="Country"
                                            styles={colourStyles}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2 form-group">
                                    <div className="col-12">
                                        <input onChange={this.handleChange} placeholder="Address" className="form-control" name="address_line1" value={this.state.address_line1}  />
                                    </div>
                                </div>
                                <div className="row mb-2 form-group">
                                    <div className="col-7">
                                        <input onChange={this.handleChange} placeholder="Company Name" className="form-control" name="company_name" value={this.state.company_name}  />
                                    </div>
                                    <div className="col-5">
                                        <input onChange={this.handleChange} placeholder="VAT number" className="form-control" name="vat" value={this.state.vat}  />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col">
                                        {
                                            this.state.showValidationErrors ? (
                                                <div className="text-red mt-2">
                                                    <p>Please fill in all required fields</p>
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            errors ? (
                                                <div className="text-red mt-2">
                                                    {
                                                        Object.keys(errors).map((key) => (
                                                            <p key={key}>{errors[key]}</p>
                                                        ))
                                                    }
                                                </div>
                                            ) : ''
                                        }
                                        <button className="btn-block btn btn-success change-button" disabled={!this.state.enablePayButton}>
                                            Change to Professional Plan ${this.props.selectedPricingPlan.price}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )
                }

            </>
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    errors: filteredErrors(state)('payment', 'block'),
    selectedPricingPlan: selectedPricingPlan(state),
    countries: countriesSelector(state)

});

const MapDispatchToProps = dispatch => ({
    pay: (token, meta) => dispatch(pay(token, meta)),
    setError: (error) => dispatch(setError('payment', error, 'block'))
});
export default injectStripe(connect(MapStateToProps, MapDispatchToProps)(StripeForm));