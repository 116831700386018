export const HIDE_NAV   = 'navigation/HIDE';
export const TOGGLE_NAV = 'navigation/TOGGLE';

const defaultState = {
    user: null,
    modals: {
        login: false,
        register: false,
        forgot: false,
        payment: false
    },
    globalPreloader: false,
    selectedPricingPlan: null,
    errors: {},
    settings: {
        app_name: 'Complevel'
    },
    showMobileNav: false
};

export function mainData(state = defaultState, action) {
    switch(action.type) {
        case 'SHOW_MODAL': {
            let modals = {};
            Object.keys(state.modals).forEach(key => (modals[key] = false));
            modals[action.modal] = true;
            return {
                ...state,
                modals: modals
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                modals: {...state.modals, [action.modal]: false}
            }
        }
        case 'SET_USER': {
            return {
                ...state,
                user: action.user
            }
        }
        case 'SET_USER_FIELD': {
            return {
                ...state,
                user: {...state.user, [action.field]: action.value}
            }
        }
        case 'SET_GLOBAL_PRELOADER': {
            return {
                ...state,
                globalPreloader: action.status
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errors: {...state.errors, [action.group]: action.errors}
            }
        }
        case 'SET_SELECTED_PRICING_PLAN': {
            return {
                ...state,
                selectedPricingPlan: action.plan
            }
        }
        case 'SET_SETTINGS': {
            return {
                ...state,
                settings: action.settings
            }
        }
        case HIDE_NAV: {
            return {
                ...state,
                showMobileNav: false
            }
        }
        case TOGGLE_NAV: {
            return {
                ...state,
                showMobileNav: !state.showMobileNav
            }
        }
        default:
            return state;
    }
}