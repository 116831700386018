import React from 'react';

const Icon = ({ id, width = 24, height = 24, className = ''}) => {
    return (
        <svg className={`icon ${className}`} width={width} height={height}>
            <use xlinkHref={`#${id}`}/>
        </svg>
    )
};
export default Icon;
