import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getUser} from 'actions/main.actions';
import {userSelector, settingsSelector} from 'selectors/main.selectors';
import {Route, Switch} from 'react-router-dom';
import {CustomizeBenchmark, ForACompany} from './../components/Search';
import {ReactTitle} from 'react-meta-tags';

class Search extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isAdvanced: 0
        };

        this.changeSearchType = this.changeSearchType.bind(this);

    }

    changeSearchType(isAdvanced) {
        let url = '/search/' + (isAdvanced ? 'customize' : '');
        this.props.history.push(url);
        // this.setState({isAdvanced: isAdvanced});
        // window.scrollTo(0, 0);
    }

    render() {
        let isAdvanced = ('customize' === this.props.searchType);
        let user = this.props.user;
        return (
            <>
            <ReactTitle title={`Search | ${this.props.settings.app_name} - Executive Compensation Benchmarking`}/>

            <section className="bg-primary-3 header-inner p-0 jarallax position-relative o-hidden">
                <div className="container py-0 layer-2">
                    <div className="row my-3 aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-8 col-12 offset-md-2 offset-0">
                            <h2 className="display-4">Search</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {
                                isAdvanced
                                    ? <CustomizeBenchmark
                                        history={this.props.history}
                                        user={user}
                                        setAdvanced={this.changeSearchType}/>
                                    : <ForACompany
                                        history={this.props.history}
                                        setAdvanced={this.changeSearchType}
                                        user={user}
                                        showModal={this.props.showModal}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </section>

            </>
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    settings: settingsSelector(state),
});

const MapDispatchToProps = dispatch => ({});

export default connect(MapStateToProps, MapDispatchToProps)(Search);
