import { createSelector } from 'reselect';

const mainDataSelector = state => state.searchData;

export const filtersSelector = createSelector(
        mainDataSelector,
        data => data.filters
);

export const positionsSelector = createSelector(
    filtersSelector,
    data => data.positions
);


export const resultsSelector = createSelector(
    mainDataSelector,
    data => data.results
);

export const resultIdSelector = createSelector(
    resultsSelector,
    data => data.result_id
);

export const selectedCompaniesSelector = createSelector(
    resultsSelector,
    data => data.companies ? data.companies.filter(item => !item.is_removed) : null
);

export const removedCompaniesSelector = createSelector(
    resultsSelector,
    data =>  data.companies ? data.companies.filter(item => item.is_removed && !item.is_restoring) : null
);

export const restoringCompaniesSelector = createSelector(
    resultsSelector,
    data =>  data.companies ? data.companies.filter(item => item.is_restoring) : null
);

export const allCompaniesSelector = createSelector(
    mainDataSelector,
    data =>  data.allCompanies
);

export const additionalCompaniesSelector = createSelector(
    mainDataSelector,
    data => data.additionalCompanies
);

export const selectedFiltersSelector = createSelector(
    mainDataSelector,
    data => data.selectedFilters
);