import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import Header from "./components/Header";
import store from "./store/index";
import App from './App';


const files = require.context('./../public/img/', true, /\.svg$/);
files.keys().forEach(files);

const rootElement = document.getElementById('root')
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement
);