import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getPage, getUser, modal, preloader, setPricingPlan} from 'actions/main.actions';
import {settingsSelector, userSelector} from 'selectors/main.selectors';
import ContactForm from './../components/ContactForm';
import {MetaTags, ReactTitle} from 'react-meta-tags';
import {HIDE_NAV} from 'reducers/main.reducers';

class Static extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            page: null
        };
        this.showPaymentModal = this.showPaymentModal.bind(this);
        this.loadPage = this.loadPage.bind(this);

    }

    componentDidUpdate(prevProps) {
        if(this.props.match.url !== prevProps.match.url){
            this.loadPage();
        }
    }

    componentDidMount() {
        this.loadPage();
    }

    loadPage(){
        let self = this;
        if ('undefined' !== typeof this.props.match.params.page) {
            this.props.preloader();
            getPage(this.props.match.params.page).then(result => {
                self.setState({page: result}, () => {
                    let pricingBtns = document.getElementsByClassName('pricing-btn');
                    Array.from(pricingBtns).forEach(item => {
                        item.addEventListener('click', e => this.showPaymentModal(item.dataset.plan))
                    });
                    this.props.hideNav();
                    self.props.preloader(false);
                    if('undefined' !== typeof window.location.hash.substr(1)){
                        let el = document.getElementById(window.location.hash.substr(1));
                        if(el) {
                            const y = el.getBoundingClientRect().top + window.pageYOffset - 70;

                            window.scrollTo({top: y, behavior: 'smooth'});
                        }
                    }
                });
            })
                .catch(error => {
                    self.props.history.push('/404')
                    self.props.preloader(false)
                });
        }
    }

    componentWillUnmount() {
        this.setState({page: null})
    }

    showPaymentModal(plan_name) {
        if (this.props.user && ('free' === plan_name || 'professional' === this.props.user.current_plan))
            return this.props.history.push('/search');

        let plan = this.props.settings.pricing_plans.filter(item => plan_name === item.system_name);
        if (plan.length)
            this.props.setPricingPlan(plan[0]);
        let modal = (this.props.user) ? 'payment' : 'register';
        this.props.showModal(modal);
    }

    render() {
        let page = this.state.page;

        return (
            <>
            {
                page ? (
                    <>
                        <ReactTitle title={`${page.title} | ${this.props.settings.app_name} - Executive Compensation Benchmarking`}/>
                        <MetaTags>
                            <meta name="description" content={`${page.seo_description}`} />
                        </MetaTags>
                        <div dangerouslySetInnerHTML={{__html: page.body}}></div>
                    </>
                ) : null
            }
            {
                page && ('about' === this.props.match.params.page) ? <ContactForm/> : null
            }
            </>
        );
    }
}

const MapStateToProps = state => ({
    user: userSelector(state),
    settings: settingsSelector(state),
});

const MapDispatchToProps = dispatch => ({
    setPricingPlan: (plan) => dispatch(setPricingPlan(plan)),
    showModal: (type) => dispatch(modal(type)),
    preloader: (state) => dispatch(preloader(state)),
    hideNav: () => dispatch({type: HIDE_NAV})
});

export default connect(MapStateToProps, MapDispatchToProps)(Static);
