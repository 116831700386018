import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return (
           <div>404</div>
        );
    }
}

export default NotFound;