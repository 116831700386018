import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login, preloader, setError} from 'actions/main.actions';
import {filteredErrors, variablesSelector} from 'selectors/main.selectors';
import Base from './Base';
import { withRouter } from "react-router";

class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            password: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.props.setError('login', null, 'block');
    }

    onSubmit(event) {
        const {redirect_after_login} = this.props.variables;

        this.props.preloader(true);
        const {email, password} = this.state;
        this.props.login(email, password).then(() => {
            this.props.history.push(redirect_after_login || '/search');
        }).finally(() => this.props.preloader(false)).catch(()=>{

        });
        event.preventDefault();
    }

    render() {
        let errors = this.props.errors;
        return (
            <Base {...this.props} modalId="login">
                <div className="text-center mb-4">
                    <h4 className="h3 mb-1">Welcome back</h4>
                    <span>Enter your account details below</span>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <input type="email" name="email" placeholder="Email Address"
                               className="form-control" value={this.state.email} onChange={this.handleChange}/>

                    </div>
                    <div className="form-group">
                        <input type="password" name="password" placeholder="Password"
                               className="form-control" value={this.state.password}
                               onChange={this.handleChange}/>
                        <small><a href="#" onClick={() => this.props.showModal('forgot')}>Forgot your password?</a>
                        </small>
                    </div>
                    <div className="form-group">
                        <button className="btn-block btn btn-primary-2" type="submit">
                            Log in
                        </button>
                        {
                            errors ? (
                                <div className="text-red mt-2">
                                    {
                                        Object.keys(errors).map((key) => (
                                            <p key={key}>{errors[key]}</p>
                                        ))
                                    }

                                </div>
                            ) : ''
                        }
                    </div>
                    <hr/>
                    <div className="text-center text-small text-muted">
                        <span>Don't have an account yet? <a href="#" onClick={() => this.props.showModal('register')}>Create one</a></span>
                    </div>
                </form>
            </Base>
        );
    }
}


const MapStateToProps = state => ({
    errors: filteredErrors(state)('login', 'block'),
    variables: variablesSelector(state)
});

const MapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(login(email, password)),
    preloader: state => dispatch(preloader(state)),
    setError: (group, errors, type) => dispatch(setError(group, errors, type))
});

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(Login));