export default function serializeParams(params) {
    return Object.keys(params).map((key, index) => {
        if (params[key] !== null) {
            if (index === 0) {
                return `${key}=${params[key]}`;
            }
            return `&${key}=${params[key]}`;
        }
        return '';
    }).join('');
}
