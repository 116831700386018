import React from 'react';
import InputRange from 'react-input-range';
import numeral from 'numeral';
import 'react-input-range/lib/css/index.css';

const RangeField = ({value, maxValue, minValue, label, name, handleChange, handleChangeComplete, rightLabel = ''}) => {
    return (
        <div className="row form-group">
            <div className="col-md-8">
                <div className="row">
                    <div className="col-md-3 col-12 mb-1 mb-md-0">
                        <label htmlFor="headcount">{label}</label>
                    </div>
                    <div className="col-md-9 col-12 range">
                        <InputRange
                            name='headcount'
                            maxValue={maxValue}
                            minValue={minValue}
                            value={value}
                            onChange={(value) => handleChange(name, value)}
                            onChangeComplete={() => handleChangeComplete()}
                            formatLabel={value => numeral(value).format('0a')}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-3 mb-2 mb-md-0">
                <div className="row">
                    <div className="col-md-4 col-3">
                        <input type="text" className="form-control"
                               value={numeral(value.min).format('0,0')}
                               onChange={(event) => handleChange(name, event.target.value, 'min')}/>
                    </div>
                    <div className="col-md-2 col-1 text-center">
                        TO
                    </div>
                    <div className="col-md-4 col-3">
                        <input type="text" className="form-control"
                               value={numeral(value.max).format('0,0')}
                               onChange={(event) => handleChange(name, event.target.value, 'max')}/>
                    </div>
                    <div className="col-md-2 col-1 text-center">
                        {rightLabel}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default RangeField;
