import React, {Component} from 'react';
import {connect} from 'react-redux';
import {modal, preloader, setPricingPlan, setUserField} from 'actions/main.actions';
import {settingsSelector} from 'selectors/main.selectors';
import {changeFilter, loadFilters, search, searchCompanies} from 'actions/search.actions';
import {positionsSelector, selectedFiltersSelector} from 'selectors/search.selectors';

import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

class ForACompany extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            search: '',
            filtersValidated: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validateFilters = this.validateFilters.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.showPaymentModal = this.showPaymentModal.bind(this);

    }

    handleChange(name, value) {
        this.props.changeFilter(name, value);
        setTimeout(() => this.validateFilters(), 0);
    }

    onSubmit(event) {
        let history = this.props.history;
        this.props.changeFilter('year', {label: 2019, value: 2019});
        this.props.search().then(() => history.push('/results'))
            .catch((error) => {
                if (('undefined' !== error.error) && ('cant_search' === error.error)) {
                    this.props.setUserField('can_search', false);
                }
            });
        event.preventDefault();
    }

    componentDidMount() {
        if (!this.props.positions.length)
            this.props.loadFilters();

        this.validateFilters();
    }

    validateFilters() {
        this.setState({filtersValidated: (null !== this.props.selectedFilters.position)});
    }


    loadOptions(inputValue, callback) {
        // if(!inputValue.length)
        //     return false;

        searchCompanies(inputValue).then((result) => {
            let companies = result.map(item => ({label: item.name, value: item.id}));
            callback(companies);
        });
    };

    showPaymentModal() {
        let plan_name = 'professional';
        let plan = this.props.settings.pricing_plans.filter(item => plan_name === item.system_name);
        if (plan.length)
            this.props.setPricingPlan(plan[0]);
        this.props.showModal('payment');
    }

    render() {
        let positions = [];
        if (this.props.positions.length)
            positions = this.props.positions.map(item => ({value: item.id, label: item.name, description: item.description}));
        let {selectedFilters} = this.props;
        return (
            <div className="row">
                <div className="col-md-8 col-12 offset-md-2 offset-0">
                    <div className="row">
                        <div className="col">
                            <h4>Instructions</h4>
                            <p>There are two ways to search for data:</p>
                            <ol className="list-instructions">
                                <li>
                                    Type company name and position, you want to get benchmark data for and let the tool find data for comparable positions in similar companies.
                                </li>
                                <li>
                                    Customize your database query
                                </li>
                            </ol>
                        </div>
                    </div>
                    {
                        this.props.user && this.props.user.can_search
                            ? (
                                <>
                                <div className="row mb-3">
                                    <div className="col">
                                        <form onSubmit={this.onSubmit} className="form">
                                            <div className="form-group mb-3">
                                                <label htmlFor="search">Company Name</label>
                                                <AsyncSelect cacheOptions defaultOptions={false}
                                                             loadOptions={this.loadOptions}
                                                             value={selectedFilters.company}
                                                             onChange={(value) => this.handleChange('company', value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="position">Position<span
                                                    className="text-red">*</span></label>
                                                <Select
                                                    name="position"
                                                    value={selectedFilters.position}
                                                    options={positions}
                                                    onChange={(value) => this.handleChange('position', value)}
                                                    placeholder="Select Position..."
                                                    isMulti="true"
                                                />
                                            </div>
                                            {
                                                this.state.filtersValidated ? (
                                                    <button className="mt-1 btn btn-primary-2 float-right btn_size_2 ">
                                                        <span>GO</span>
                                                    </button>
                                                ) : (
                                                    <div className="col-md-7 col-12 alert alert-primary fade show float-right"
                                                         role="alert">
                                                        <p className="text-center">Please fill in ALL required fields</p>
                                                    </div>
                                                )
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <p>OR</p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button onClick={() => this.props.setAdvanced(1)}
                                                className="form-control btn btn-primary-2 btn_size_3">
                                            Customized Benchmark
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <p>OR</p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button disabled={true} className="form-control btn btn-primary-2 btn_size_3">Trend Analyses and Side by Side
                                        </button>
                                    </div>
                                </div>
                                </>

                            )
                            : (<div className="row">
                                <div className="col">
                                    <div className="alert alert-primary fade show" role="alert">
                                        <p>
                                            You have exceeded the limit of requests in Free pricing plan. &nbsp;
                                            <button className="btn btn-sm btn-white border" onClick={this.showPaymentModal}>
                                                Get Pro version now
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>)
                    }
                </div>
            </div>
        );
    }
}


const MapStateToProps = state => ({
    positions: positionsSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    settings: settingsSelector(state)

});

const MapDispatchToProps = dispatch => ({
    loadFilters: () => dispatch(loadFilters('positions')),
    search: (filters) => dispatch(search('for_a_company', filters)),
    changeFilter: (name, value) => dispatch(changeFilter(name, value)),
    setPricingPlan: (plan) => dispatch(setPricingPlan(plan)),
    showModal: (type, state) => dispatch(modal(type, state)),
    setUserField: (field, value) => dispatch(setUserField(field, value))
});

export default connect(MapStateToProps, MapDispatchToProps)(ForACompany);