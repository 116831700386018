import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login, modal} from 'actions/main.actions';
import {settingsSelector} from 'selectors/main.selectors';
import Base from './Base';
import {StripeProvider, CardElement, Elements} from 'react-stripe-elements';
import {Stripe} from './../Payment';

class Payment extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <Base {...this.props} modalId="payment">
                    <div className="text-center mb-4">
                        <h4 className="h3 mb-1"> Professional Plan</h4>
                        <span>Are you ready to go to the Pro Plan?</span>
                    </div>

                {
                    this.props.settings.stripe_public_key ?(
                        <StripeProvider apiKey={this.props.settings.stripe_public_key}>
                            <Elements>
                                <Stripe showModal={this.props.showModal} history={this.props.history}/>
                            </Elements>
                        </StripeProvider>
                    ) : null
                }
            </Base>
        );
    }
}


const MapStateToProps = state => ({
    settings: settingsSelector(state)
});

const MapDispatchToProps = dispatch => ({
    showModal: (type, state) => dispatch(modal(type, state)),
});

export default connect(MapStateToProps, MapDispatchToProps)(Payment);