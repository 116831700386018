import React, {Component} from 'react';
import {connect} from 'react-redux';
import {filtersSelector, selectedFiltersSelector} from 'selectors/search.selectors';
import {changeFilter, loadFilters, search} from 'actions/search.actions';
import {setUserField} from 'actions/main.actions';


import Select from 'react-select';
import numeral from 'numeral';
import RangeField from 'RangeField';
import {settingsSelector} from "../../selectors/main.selectors";

// switch between locales

class CustomizeBenchmark extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            researchPreloader: false,
            searchedCount: 0,
            filtersValidated: false
        };

        this.handleChangeRange = this.handleChangeRange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.validateFilters = this.validateFilters.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.research = this.research.bind(this);

    }

    handleChangeRange(name, value, type = false) {
        if (this.state.researchPreloader) return false;
        if ('' === value)
            value = 0;
        if (type) {
            value = numeral(value).value();
            if ('min' === type)
                if ((value < this.props.filters[name].min) || (value > this.props.selectedFilters[name].max))
                    return false;

            if ('max' === type)
                if ((value > this.props.filters[name].max) || (value < this.props.selectedFilters[name].min))
                    return false;

            let newValue = Object.assign({}, this.props.selectedFilters[name]);
            newValue[type] = parseInt(value);
            value = newValue;
        }
        this.props.changeFilter(name, value);
    }

    handleChangeSelect(name, value) {
        if (this.state.researchPreloader) return false;

        this.props.changeFilter(name, value);
        setTimeout(() => this.research(), 0);
    }

    onSubmit(event) {
        let history = this.props.history;
        this.props.search().then(() => history.push('/results')).catch((error) => {
            if (('undefined' !== error.error) && ('cant_search' === error.error)) {
                this.props.setUserField('can_search', false);
                this.props.setAdvanced(0);
            }
        });
        event.preventDefault();
    }

    research() {
        this.validateFilters();
        if (!this.state.filtersValidated)
            return false;
        this.setState({researchPreloader: true});
        this.props.research().then((response) => {
            this.setState({searchedCount: response.count, researchPreloader: false});
        });
    }

    componentDidMount() {
        if (!this.props.filters.all_is_loaded) {
            this.props.loadFilters().then(response => this.research());
        }
        setTimeout(() => this.research(), 0);
    }

    validateFilters() {
        this.setState({filtersValidated: (null !== this.props.selectedFilters.position)});
    }

    render() {
        let positions = [], sectors = [], years = [], states = [];
        if (this.props.filters.all_is_loaded) {
            positions = this.props.filters.positions.map(item => ({value: item.id, label: item.name, description: item.description}));
            sectors = this.props.filters.sectors.map(item => ({value: item.id, label: item.name}));
            years = this.props.filters.years.map(item => ({value: item, label: item}));
            states = this.props.filters.states.map(item => ({value: item.id, label: item.name}));
        }
        let {selectedFilters} = this.props;
        return (
            <div className="row">
                <div className="col-12">
                    {
                        this.props.filters.all_is_loaded ? (
                            <form onSubmit={this.onSubmit} className="form">
                                <div className="range-wrapper">
                                <RangeField
                                    value={selectedFilters.revenue}
                                    maxValue={this.props.filters.revenue.max}
                                    minValue={this.props.filters.revenue.min}
                                    label="Revenue"
                                    name="revenue"
                                    handleChange={this.handleChangeRange}
                                    handleChangeComplete={() => this.research()}
                                    rightLabel="(MUSD)"
                                />

                                <RangeField
                                    value={selectedFilters.market_cap}
                                    maxValue={this.props.filters.market_cap.max}
                                    minValue={this.props.filters.market_cap.min}
                                    label="Market Cap"
                                    name="market_cap"
                                    handleChange={this.handleChangeRange}
                                    handleChangeComplete={() => this.research()}
                                    rightLabel="(MUSD)"
                                />

                                <RangeField
                                    value={selectedFilters.headcount}
                                    maxValue={this.props.filters.headcount.max}
                                    minValue={this.props.filters.headcount.min}
                                    label="Headcount"
                                    name="headcount"
                                    handleChange={this.handleChangeRange}
                                    handleChangeComplete={() => this.research()}
                                    rightLabel="(employees)"
                                />
                                </div>


                                <div className="row">
                                    <div className="col-md-8 col-12">
                                        <div className="form-group row">
                                            <div className="col-md-3 col-12 ">
                                                <label htmlFor="year">Year</label>
                                            </div>
                                            <div className="col-md-9 col-12">
                                                <Select
                                                    name="year"
                                                    value={selectedFilters.year}
                                                    options={years}
                                                    onChange={(value) => this.handleChangeSelect('year', value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-3 col-12 ">
                                                <label htmlFor="position">Position<span
                                                    className="text-red">*</span></label>
                                            </div>
                                            <div className="col-md-9 col-12">
                                                <Select
                                                    name="position"
                                                    value={selectedFilters.position}
                                                    options={positions}
                                                    onChange={(value) => this.handleChangeSelect('position', value)}
                                                    placeholder="Select Position..."
                                                    isMulti="true"
                                                />
                                            </div>
                                        </div>
                                        <div className={"form-group row" + ((('undefined' === typeof this.props.user) || ('professional' !== this.props.user.current_plan)) ? ' disabled-block' : '')}>
                                            <div className="col-md-3 col-12 ">
                                                <label htmlFor="sector">Sectors</label>
                                            </div>
                                            <div className="col-md-9 col-12">
                                                <Select
                                                    name="sector"
                                                    value={selectedFilters.sector}
                                                    options={sectors}
                                                    onChange={(value) => this.handleChangeSelect('sector', value)}
                                                    isMulti="true"
                                                />
                                            </div>
                                        </div>
                                        <div className={"form-group row" + ((('undefined' === typeof this.props.user) || ('professional' !== this.props.user.current_plan)) ? ' disabled-block' : '')}>
                                            <div className="col-md-3 col-12 ">
                                                <label htmlFor="state">Locations</label>
                                            </div>
                                            <div className="col-md-9 col-12">
                                                <Select
                                                    name="state"
                                                    value={selectedFilters.state}
                                                    options={states}
                                                    onChange={(value) => this.handleChangeSelect('state', value)}
                                                    isMulti="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-4 col-12" + (this.state.researchPreloader ? ' preloader' : '')}>
                                        {
                                            this.state.filtersValidated ? (
                                                <div className="card card-body text-center marg-right">
                                                    <h6>Job matches</h6>
                                                    <h5>{this.state.searchedCount}</h5>
                                                    <button className="m-1 btn btn-primary-2" type="submit">
                                                        <span>Show Data</span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="alert alert-primary fade show" role="alert">
                                                    <p className="text-center">Please fill in ALL required fields</p>
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </form>
                        ) : ''
                    }
                </div>
                <div className="col-md-8 col-12">
                    <div className="row mb-3">
                        <div className="col-12 ">
                            <p className="align-right-or">OR</p>
                        </div>
                        <div className="col-12 text-right ">
                            <button onClick={() => this.props.setAdvanced(0)} className="form-control btn btn-primary-2 btn_size_1">
                                Back to Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const MapStateToProps = state => ({
    filters: filtersSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    settings: settingsSelector(state),
});

const MapDispatchToProps = dispatch => ({
    loadFilters: () => dispatch(loadFilters('', true)),
    research: () => dispatch(search('customize_benchmark', true)),
    changeFilter: (name, value) => dispatch(changeFilter(name, value)),
    search: () => dispatch(search('customize_benchmark')),
    setUserField: (field, value) => dispatch(setUserField(field, value))
});

export default connect(MapStateToProps, MapDispatchToProps)(CustomizeBenchmark);