import React, {Component} from 'react';
import {connect} from 'react-redux';
import {forgotPassword, login, modal, preloader} from 'actions/main.actions';
import {filteredErrors, modalsSelector} from 'selectors/main.selectors';
import Base from './Base';

class Forgot extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            mail_sent: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onSubmit(event) {
        event.preventDefault();
            this.props.preloader(true);
            const {email} = this.state;
            this.props.forgotPassword(email).then(() => {
                this.setState({mail_sent: true});
                this.props.preloader(false);
            }).finally(() => this.props.preloader(false)).catch(()=>{

            });
    }

    render() {
        let errors = this.props.errors;
        return (
            <Base {...this.props} modalId="forgot">
                <div className="text-center mb-4">
                    <h4 className="h3 mb-1">Forgot Password</h4>
                    <span>Enter your account details below</span>
                </div>
                {
                    !this.state.mail_sent ?
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <input type="email" name="email" placeholder="Email Address"
                                       className="form-control" value={this.state.email} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <button className="btn-block btn btn-primary-2" type="submit">
                                    Send Email
                                </button>
                                {
                                    errors ? (
                                        <div className="text-red mt-2">
                                            {
                                                Object.keys(errors).map((key) => (
                                                    <p key={key}>{errors[key]}</p>
                                                ))
                                            }

                                        </div>
                                    ) : ''
                                }
                            </div>
                        </form>
                        :
                        <div>
                            We have sent a password reset email. Please check your email.
                        </div>

                }
            </Base>
        );
    }
}


const MapStateToProps = state => ({
    errors: filteredErrors(state)('forgot_password', 'block')
});

const MapDispatchToProps = dispatch => ({
    preloader: state => dispatch(preloader(state)),
    forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(MapStateToProps, MapDispatchToProps)(Forgot);