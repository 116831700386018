import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Icon from './../Icon';

class Base extends Component {
    render() {
        return (
            <Modal show={this.props.isShow} onHide={() => this.props.showModal(this.props.modalId, 0)}>
                <Modal.Body>
                    <button type="button" className="close" onClick={() => this.props.showModal(this.props.modalId, 0)}>
                        <Icon id="close-modal"/>
                    </button>
                    <div className="m-xl-4 m-3">
                        {this.props.children}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default Base;